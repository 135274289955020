<template>
  <div>
    <b-row class="mt-2">
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="SettingsIcon" statistic="General Settings" link="general" statistic-title="General Settings" color="secondary" />
      </b-col> -->
      <!-- <b-col xl="4" md="4" sm="6" v-show="$Can('see_permission')">
        <settings-card icon="BarChart2Icon" :statistic="$t('settings.menu.historical_price')" link="userrole" statistic-title="User Roles" color="success" />
      </b-col> -->
      <b-col xl="4" md="4" sm="6" v-if="$Can('admin_panel')">
     
        <settings-card icon="AirplayIcon" :statistic="$t('settings.menu.admin_panel')" link="admin-panel" statistic-title="Admin Panel" color="secondary" :activate-list="activateList"
        @update:activateList="activateList = $event"/>

        <!-- <b-card  v-if="!activateList" @click="toggleActivateList">s
          <b-avatar class="mb-1" variant="`light-secondary`" size="45">
            <feather-icon size="21" icon="AirplayIcon" />
          </b-avatar>
          <div class="truncate">
            <h4 class="mb-15 font-weight-bolder">
              Admin Panel
            </h4>
          </div>
        </b-card> -->
        <b-list-group v-if="activateList">

          <b-list-group-item v-if="$Can('access_currencies')" @click="navigateToRoute('manage-currencies')">
           <span class="mr-1">
        <feather-icon
          icon="DollarSignIcon"
          size="16"
        />
      </span>
      <span>Manage Currencies</span>
        </b-list-group-item>

        <b-list-group-item v-if="$Can('access_cost_centers')" @click="navigateToRoute('manage-cost-centers')">
           <span class="mr-1">
        <feather-icon
          icon="ClipboardIcon"
          size="16"
        />
      </span>
      <span>Manage Cost Centers</span>
        </b-list-group-item>

        <b-list-group-item v-if="$Can('access_users')" @click="navigateToRoute('manage-users')">
           <span class="mr-1">
        <feather-icon
          icon="UsersIcon"
          size="16"
        />
      </span>
      <span>Manage Users</span>
        </b-list-group-item>
        <b-list-group-item v-if="$Can('access_intermediaries')" @click="navigateToRoute('manage-intermediaries')">
           <span class="mr-1">
        <feather-icon
          icon="VoicemailIcon"
          size="16"
        />
      </span>
     <span>Manage Intermediaries</span>
        </b-list-group-item>
        <b-list-group-item v-if="$Can('access_default_tickets')" @click="navigateToRoute('manage-default-tickets')">
           <span class="mr-1">
        <feather-icon
          icon="UserCheckIcon"
          size="16"
        />
      </span>
     <span>Manage Default Ticket Visibility</span>
        </b-list-group-item>
        <b-list-group-item v-if="$Can('access_emails')" @click="navigateToRoute('manage-emails')">
           <span class="mr-1">
        <feather-icon
          icon="MailIcon"
          size="16"
        />
      </span>
     <span>Manage Emails</span>
        </b-list-group-item> 
        <b-list-group-item v-if="$Can('access_holidays')" @click="navigateToRoute('manage-holidays')">
           <span class="mr-1">
        <feather-icon
          icon="BookmarkIcon"
          size="16"
        />
      </span>
      <span>Manage Holidays</span>
        </b-list-group-item>   
        <b-list-group-item v-if="$Can('access_timeline')" @click="navigateToRoute('action-timeline')">
           <span class="mr-1">
        <feather-icon
          icon="DiscIcon"
          size="16"
        />
      </span>
      <span>Action Time Line</span>
        </b-list-group-item> 
        <b-list-group-item v-if="$Can('access_tax_rates')" @click="navigateToRoute('manage-tax-rates')">
           <span class="mr-1">
        <feather-icon
          icon="PercentIcon"
          size="16"
        />
      </span>
      <span>Manage Tax Rates</span>
        </b-list-group-item>   
        <b-list-group-item v-if="$Can('access_commissions')" @click="navigateToRoute('manage-commissions')">
           <span class="mr-1">
        <feather-icon
          icon="PercentIcon"
          size="16"
        />
      </span>
      <span>Manage Commissions</span>
        </b-list-group-item>
        <b-list-group-item v-if="$Can('access_tax_rates')" @click="navigateToRoute('manage-historical-prices')">
           <span class="mr-1">
        <feather-icon
          icon="DollarSignIcon"
          size="16"
        />
      </span>
      <span>Manage Historical Prices</span>
        </b-list-group-item>  
        <b-list-group-item v-if="$Can('access_tax_rates')" @click="navigateToRoute('manage-future-specs')">
           <span class="mr-1">
        <feather-icon
          icon="BookIcon"
          size="16"
        />
      </span>
      <span>Manage Future Specs</span>
   
        </b-list-group-item> 
        
    

   
  </b-list-group>

      </b-col>      

      <b-col xl="4" md="4" sm="6" v-if="$Can('admin_permissions')">
        <settings-card icon="ShieldIcon" :statistic="$t('settings.menu.role_and_permissions')" link="user-role" statistic-title="User Roles" color="success" />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-if="$Can('access_personal_settings')">
        <settings-card icon="ShieldIcon" :statistic="$t('settings.menu.personal_settings')" link="personal-settings" statistic-title="User Roles" color="success" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination,BAvatar } from 'bootstrap-vue';

import useUserList from './useSettings';

import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from './settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BAvatar,

    SettingsCard,
  },
  data() {
    return {
      activateList :false,
   
      };
    },

  methods: {

    navigateToRoute(routeName) {
      // Use $router.push() to navigate to the specified route
      this.$router.push({ name: 'settings-' + routeName });
    },
    toggleActivateList() {
      console.log("Toggling activateList");
      this.activateList = !this.activateList;
    },


  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
    
  },

  mounted() {},
  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },
};
</script>
